import { generalMixin } from "@/utils/general-mixin";
import { mapGetters } from "vuex";
import { createComment, getCommentList } from "@/api/comment.js";

/*
  This is used by :
  1. Comment Section
  2. Comment Box

  Usage:
  This mixin provides common logic for:
  1. Fetching and displaying comment lists.
  2. Submitting new comments.

  Note: `mountedFn` is assumed to be a method defined in the consuming component 
  where this mixin is used. It should handle any necessary reinitialization 
  or update logic after a comment has been successfully posted
  (Re-run any setup or reloading logic after posting)

  DO NOT CHANGE ANYTHING HERE,
  you can only override anything in your own component

*/

const emptyPhotoUrl = require("@/assets/pngs/user-b.png");

export const commentMixin = {
  mixins: [generalMixin],
  watch: {},
  data() {
    return {
      commentText: "",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
  },
  methods: {
    getPhotoUrl(userObj) {
      let result = userObj.photo?.upload?.url;
      if (result == null) result = emptyPhotoUrl;
      return result;
    },
    postComment() {
      if (this.commentText == "" || this.commentText == null) {
        this.$notify({
          type: "error",
          message: this.$t("COMMENT_CANNOT_EMPTY"),
          duration: this.NOTIFY_DURATION,
          position: this.NOTIFY_POSITION,
        });
        return;
      }
      const postData = {
        user: { id: this.user.id },
        article: { id: this.articleId },
        text: this.commentText,
      };

      if (this.parentCommentId) {
        postData.parentComment = {
          id: this.parentCommentId,
        };
      }
      this.isLoading = true;
      createComment(postData)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("POSTED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION,
            });
            if (res.result?.coins && res.result?.transactionType === "IN") {
              this.$notify({
                title: this.$t("GAINED_COIN_TITLE"),
                message: this.$t("GAINED_COIN_MSG", {
                  amount: res.result.coins,
                }),
                type: "success",
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION,
                dangerouslyUseHTMLString: true,
              });
            }
            
            if (this.parentCommentId) {
              this.showInputField = !this.showInputField
            }              
            this.mountedFn();

          }
        })
        .finally(() => (this.isLoading = false));
    },
    async getList(amount = 1, isParentComment = true) {
      let offset = isParentComment ? this.totalLoaded : this.totalLoadedReplied;
      const postData = {
        offset,
        orderCol: "created_date",
        limit: amount,
        desc: true,
        articleId: this.articleId,
        status: "ACTIVE"
      };
      if (this.parentCommentId != null && !isParentComment) {
        postData.parentCommentId = this.parentCommentId
      }
      if (this.categoryId != null) {
        postData.genericRecord = { id: this.categoryId };
      }
      let result = [];
      await getCommentList(postData).then((res) => {
        if (res.status == "ok" && res.result?.list != null) {
          result = res.result.list;
          if(isParentComment) {
            this.totalAvailable = res.result.total;
            this.totalLoaded += result.length;
          } else {
            this.totalAvailableReplied = res.result.total;
            this.totalLoadedReplied += result.length;
          }
        }
      });
      return result;
    },
  },
  mounted() {},
};
