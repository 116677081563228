<template>
<!-- 
  <share-network
    v-for="network in networks"
    :network="network.network"
    :key="network.network"
    :style="{ backgroundColor: network.color }"
    :url="articleURL"
    :title="articleObj.title | translate"
    :description="articleObj.description | translate"
    :class="network"
    @open="handleOpen"
  >
    <i :class="network.icon"></i>
    <span>{{ network.name }}</span>
  </share-network>
-->

  <div id="social-media">
    <template v-if="articleObj">
      <share-network
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :url="articleURL(articleObj)"
        :title="articleObj.title | translate"
        :description="articleObj.description | translate"
        :class="network"
        @open="handleOpen"
      >
        <i :class="network.icon"></i>
      </share-network>
    </template>
  </div>
</template>
<script>
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
import { shareArticle } from "@/api/article";
import { generalMixin } from "@/utils/general-mixin.js";

Vue.use(VueSocialSharing);

export default {
  props: {
    articleObj: {
      type: Object,
      required: true,
    },
  },
  mixins: [generalMixin],
  computed: {
  },
  data() {
    return {
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "custom-icon mini icon-share-fb",
          color: "#1877f2",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "custom-icon mini icon-share-twitter",
          color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "custom-icon mini icon-share-whatsapp",
          color: "#25d366",
        },
        {
          network: "email",
          name: "Email",
          icon: "custom-icon mini icon-share-email mini",
          color: "#333333",
        },
      ],
    };
  },
  methods: {
    handleOpen(network) {
      if (!this.isAuthenticated) return;
      let pData = {
        article: {
          id: this.articleObj.id,
        },
        shareMethod: network.toUpperCase(),
      };
      shareArticle(pData)
      .then(res => {
        const coinObtained = res.result?.coinsDto?.coins
        if (coinObtained > 0) {
          this.$notify({
            title: this.$t("GAINED_COIN_TITLE"),
            message: this.$t("GAINED_COIN_MSG", { amount: coinObtained }),
            type: 'success',
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION,
            dangerouslyUseHTMLString: true
          })
        }
      })
      .catch(this.handleError);

      // console.log(network);
    },
  },
};
</script>
<style lang="scss" scoped>
#social-media {
  display: flex;
  .network {
    padding: 0.3rem 0.5rem;
    color: #fff;  
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child):after {
      content: "";
      width: 1px;
      height: 60%;
      background: #ededed;
      float: right;
      margin-top: 5px;
      position: relative;
      left: 5px;
    }
  }
}
</style>