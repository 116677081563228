import request from "@/utils/request";

export async function getCommentList(postData) {
  return await request({
    url: '/comment/list',
    method: "post",
    data: postData,
  });
}

export async function createComment(postData) {
  return await request({
    url: '/comment/create',
    method: "post",
    data: postData,
  });
}

export async function getCommentById(id) {
  return await request({
    url: `/comment/${id}`,
    method: "get",
  });
}

export async function archiveCommentById(id) {
  return await request({
    url: `/comment/${id}`,
    method: "delete",
  });
}

export async function changeStatus(postData) {
  return await request({
    url: '/comment/change-status',
    method: "post",
    data: postData,
  });
}