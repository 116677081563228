<template>
  <el-dialog
    :fullscreen="isMobile"
    :visible.sync="isVisible"
    :custom-class="isPopup ? 'is-popup': 'ads-sample'"
    :width="isPopup ? '350px' : '50%'"
    center
    destroy-on-close
  >
    <div v-if="!isPopup">
      <el-row
        class="adv-grey-bg adv-top shadowed"
        :class="currentValue=='TOP_OF_SCREEN' ? 'adv-highlight': ''"
        type="flex"
        align="middle"
        justify="center"
      >
        <el-col :span="24">
          <div class="text-center">
            {{ $t("TOP_OF_SCREEN") }}
          </div>
        </el-col>
      </el-row>
  
      <el-row type="flex" align="top" justify="space-between">
        <el-col :span="20" class="article-sample shadowed">
          <el-skeleton :rows="3" />
          <div 
            class="adv-block adv-grey-bg"
            :class="currentValue=='MIDDLE_OF_SCREEN' ? 'adv-highlight': ''">
              {{ $t("MIDDLE_OF_SCREEN") }}</div>
          <el-skeleton :rows="3" />
        </el-col>
        <el-col
          v-show="!isMobile"
          :span="3"
          class="adv-grey-bg adv-right-sample shadowed"
          :class="currentValue=='RIGHT_SIDE_OF_SCREEN' ? 'adv-highlight': ''"
        >
          <span class="rotate-text">{{ $t("RIGHT_SIDE_OF_SCREEN") }}</span>
        </el-col>
      </el-row>
    </div>

    <div v-if="isPopup" class="entry-popup">
      {{ $t("POPUP_DURING_ARTICLE_ENTRY") }} <br> {{ $t("POPUP_DURING_ARTICLE_EXIT") }}
      <i @click="isVisible = false" class="el-dialog__close el-icon el-icon-close"></i>
    </div>

  </el-dialog>
</template>

<script>
// import cloneDeep from "lodash/cloneDeep";
import { generalMixin } from "@/utils/general-mixin.js";
import { mapState } from "vuex";

export default {
  mixins: [generalMixin],
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isPopup: { type: Boolean },
    currentValue: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState({
      list: state => state.advertisement.adsPositionList,
    })
  },
  methods: {},
  mounted() {
    this.$store.dispatch('advertisement/getAllAdsPosition')
  }
};
</script>
<style lang="scss">
@import "@/assets/style/_variables.scss";
.ads-sample {
  .adv-grey-bg {
    background: #ebebeb 0% 0% no-repeat padding-box;
    &:hover {
      box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 18%);
    }
  }
  .adv-highlight{
    font-family: $fontBaseBold;
    color: black!important;
    font-size: 17px;
  }
  .adv-top {
    height: 100px;
    margin-bottom: 1rem;    
  }

  .adv-right-sample {
    height: 25vh;
    text-align: center;
    border-radius: 5px;
    position: relative;
    .rotate-text {
      position: absolute;
      top: 0;
      left: 3rem;
      width: 25vh;
      word-break: break-word;

      /* Border is the new background */
      background: none;

      /* Rotate from top left corner (not default) */
      transform-origin: 0 0;
      transform: rotate(90deg);
    }

    .adv-right-img {
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      box-shadow: 0px 8px 36px -14px rgb(0 0 0 / 47%);
      border-radius: 10px;
      max-height: 90%;
      width: 95%;
    }
  }

  .adv-block {
    width: 100%;
    height: 4rem;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin: 1rem 0;
    letter-spacing: 0!important;
  }

  .article-sample {
    height: 40vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 2rem;
  }

}

.is-popup {
  box-shadow: 0 1px 20px 0px rgb(0 0 0 / 13%);

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    display: flex;
    text-align: center;
    height: 350px;
    width: 350px;
    align-items: center;
    justify-content: center;
    background: #ebebeb 0% 0% no-repeat padding-box;
  }

  .entry-popup {
    .el-dialog__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 96%;
      top: 0;
      height: 3rem;
      width: 3rem;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-1rem);
      cursor: pointer;
      font-size: 1.75rem;
      border: 1px solid #eaeaea;
    }
  
  }
}
</style>