<template>
  <div>
    <el-dialog
      :fullscreen="isMobile"
      :visible.sync="isVisible"
      custom-class="ads-popup"
      width="40%"
      @open="getAdsPositionList"
      center
      destroy-on-close
      :close-on-click-modal="false"
    >
      <div slot="title">
        <el-row type="flex" justify="space-between">
          <el-col class="text-left"> {{ $t("ADS_SLOT") }} </el-col>
        </el-row>
      </div>
      <div v-loading="isLoading" :class="{ mobile: isMobile }">
        {{ $t("SELECT_TO_PURCHASE") }} 
        <el-row>
          <el-col>
            <el-radio-group v-model="selectedPosition" class="ads-slots w-100">
              <el-radio
                v-for="ptype in positionList"
                :key="ptype.id"
                :label="ptype.id"
                :disabled="ptype.status === 'USED'"
                border
              >
                <span class="w-75">{{ $t(ptype.position) }}</span>
                <el-button
                  class="button text-primary borderless"
                  :class="isMobile ? 'small' : ''"
                  :disabled="ptype.status === 'USED'"
                  @click="handleViewSample(ptype.position)"
                  >{{ $t('VIEW_SAMPLE') }}</el-button
                >
                <span class="coins">
                  <b>{{ ptype.coin }}</b> {{ $t('COINS') }}</span
                >
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isVisible = false" class="w-25">{{ $t('CANCEL') }}</el-button>
        <el-button
          :disabled="!selectedPosition"
          type="primary"
          @click="goAndBuyAds(selectedPosition)"
          class="w-25"
          >{{$t('BUY')}}</el-button
        >
      </div>
  
      
    </el-dialog>
    <ads-sample-dialog v-model="dialogVisible" :isPopup="isPopup" :currentValue="currentPosition" />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { generalMixin } from "@/utils/general-mixin.js";
import { getAdsPriceList } from "@/api/advertisement";
import adsSampleDialog from "@/components/advertisement/ads-sample-dialog.vue";

export default {
  mixins: [generalMixin],
  components: { adsSampleDialog },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    articleId: { type: Number, required: true },
    preselectPosition: { type: String },
  },
  data() {
    return {
      selectedPosition: null,
      positionList: [],
      isLoading: false,
      dialogVisible: false,
      isPopup: false,
      currentPosition: null
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getAdsPositionList() {
      // console.log(this.preselectPosition);
      this.selectedPosition = null;
      this.isLoading = true;
      const adsQuery = {
        fromArticle: true,
        article: { id: this.articleId },
      };
      await getAdsPriceList(adsQuery)
        .then((res) => {
          if (res.status != "ok") return;
          this.positionList = cloneDeep(res.result);
          this.positionList.map((p) => {
            if (p.position === this.preselectPosition && p.status === 'ACTIVE')
              this.selectedPosition = p.id;
          });
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    handleViewSample(position) {
      this.isPopup = position === 'POPUP_DURING_ARTICLE_ENTRY' || position === 'POPUP_DURING_ARTICLE_EXIT'
      this.dialogVisible = true
      this.currentPosition = position
    },
    goAndBuyAds(position) {
      console.log(this.articleId,position);
      let url = `${'/bo/advertisement/detail/'+this.articleId+'/0/0?position='+position}`
      // console.log(url)
      this.$router.push(url)
      this.isVisible = false;
    },
  },
};
</script>
<style lang="scss">
.ads-popup {
  border-radius: 5px;
  font-weight: bold;
  .el-dialog__header {
    border-bottom: 1px solid #c7c7c77d;
  }
  .ads-slots {
    margin: 1rem 0;
    .el-radio {
      width: 100%;
      margin: 0.5rem 0 0.25rem !important;
      border: 1px solid #efefef;
      border-radius: 5px;
      height: 3.125rem;
      display: flex;
      align-items: center;
      padding: 10px;
      &:hover {
        box-shadow: 0px 8px 39px -14px rgba(0, 0, 0, 0.5);
      }
      .el-radio__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .coins {
          width: 80px;
          text-align: right;
        }
      }
    }
  }
  .mobile {
    .ads-slots {
      .el-radio {
        padding: 2px;
      }
      .el-radio__label {
        font-size: 12px;
      }
      .button.small {
        font-size: 10px;
      }
    }
  }
}

.mobile {
  .ads-popup {
    .el-dialog__body {
      padding: 25px 10px 30px;
    }
  }
}
</style>