<template>
  <div
    id="public-detail"
    v-loading="isLoading && articleObj"
    class="padding-btm-channel-link"
  >
    <el-row
      class="adv-grey-bg adv-top"
      type="flex"
      align="middle"
      justify="center"
    >
      <!-- TOP ADVERTISEMENT -->
      <template v-if="getAdvertisement('TOP_OF_SCREEN')">
        <el-image
          class="pointer"
          :class="{'ads': getAdsImgUrl(getAdvertisement('TOP_OF_SCREEN')[0].fileUploadList)}"
          lazy
          :src="getAdsImgUrl(getAdvertisement('TOP_OF_SCREEN')[0].fileUploadList)"
          fit="cover"
          alt=""
          @click.native="
            goToAdv(getAdvertisement('TOP_OF_SCREEN')[0].advertisementLink)
          "
        >
          <image-failed slot="error" />
        </el-image>
        <ads-complain v-if="!isPreview" :adsObj="getAdvertisement('TOP_OF_SCREEN')[0]" :articleId="id" />
      </template>
      <el-col v-else :span="24">
        <div class="text-center">
          <el-button
            size="small"
            class="btn-outline"
            @click="buyAds('TOP_OF_SCREEN')"
            :disabled="isPreview || isPreviewAdv"
            >{{ $t('BUY_ADVERTISEMENT' )}}</el-button
          >
        </div>
      </el-col>
      <!-- TOP ADVERTISEMENT -->
    </el-row> 

    <el-row id="article-main-content" class="mb-5" type="flex" align="top" justify="space-between">
      <el-col :span="getArticleColumn" class="article shadowed">
        <div class="article-header">
          <article-summary
            orientation="portrait"
            size="small"
            withImage
            withHighlight
            withWhiteBg
            withAuthor
            hideName
            hideDescription
            :articleObj="articleObj"
            showFavoriteBtn
          />
        </div>

        <div class="article-content el-zoom-in-left-enter-active">
          <p v-html="getBody"></p>
        </div>

        <div class="article-bottom">
          <el-divider />
          <el-row>
            <el-col :span="isMobile ? 24 : 15" class="complain">
              <span v-show="!isMobile">{{ $t("COMPLAINT_QUESTION") }}</span>
              <router-link v-if="isAuthenticated" :disabled="isPreview || isPreviewAdv"
                :event="(!isPreview && !isPreviewAdv) ? 'click' : ''"
                :to="`/complain/article/${id}`">
                <i class="custom-icon icon-complain-article mini" />
              {{
                $t("CLICK_TO_COMPLAIN")
              }}</router-link>
              <span v-else>
                <i class="custom-icon icon-complain-article mini" />
                {{ $t("LOG_IN_TO_COMPLAIN") }}
              </span>
            </el-col>
          </el-row>

          <el-row type="flex" justify="space-between" align="middle">
            <el-col :span="isMobile ? 8 : 15" class="like-dislike-col">
              <article-like-dislike-section
                :isPreview="this.isPreview || this.isPreviewAdv"
                :article="articleObj"
                @onInteractionChange="(interaction) => articleObj.interaction = interaction"
              />
            </el-col>
            <el-col :span="isMobile ? 15 : 6" class="buttons-col">
              <el-row :type="isMobile ? 'flex': ''" justify="end">
                <el-popover
                  v-if="!isPreview && !isPreviewAdv"
                  placement="bottom"
                  trigger="click"
                  popper-class="popup-share-article">
                  <div>
                    <socialNetwork :articleObj="articleObj" />
                    <el-divider />
                    <span class="copy" @click="copyArticleLink(articleObj)">{{ $t('COPY_ARTICLE_LINK') }}</span>
                  </div>
                  <span slot="reference" class="btn">
                    <i class="custom-icon icon-share-link mini" />
                    {{ $t('SHARE_ARTICLE') }}
                  </span>
                </el-popover>
                <span v-else class="btn">
                  <i class="custom-icon icon-share-link mini" />
                  {{ $t('SHARE_ARTICLE') }}
                </span>
                <span @click="(isPreview || isPreviewAdv) ? null : buyAds(null)" class="btn">
                  <i class="custom-icon icon-popup-ad mini" />
                  {{ $t('BUY_AD') }}
                </span>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>

      <!-- RIGHT ADVERTISEMENT -->
      <el-col
        v-show="!isMobile"
        :span="5"
        class="adv-grey-bg adv-right shadowed"
        :style="{
          top: rightAdsTopVal,
          bottom: rightAdsBtmVal,
        }"
      >
        <template v-if="getAdvertisement('RIGHT_SIDE_OF_SCREEN')">
          <el-image
            class="adv-right-img pointer"
            :class="{'ads': getAdsImgUrl(getAdvertisement('RIGHT_SIDE_OF_SCREEN')[0].fileUploadList)}"
            lazy
            :src="getAdsImgUrl(getAdvertisement('RIGHT_SIDE_OF_SCREEN')[0].fileUploadList)"
            fit="cover"
            alt=""
            @click.native="
              goToAdv(
                getAdvertisement('RIGHT_SIDE_OF_SCREEN')[0].advertisementLink
              )
            "
          >
            <image-failed slot="error" />
          </el-image>
          <ads-complain v-if="!isPreview && !isPreviewAdv" :adsObj="getAdvertisement('RIGHT_SIDE_OF_SCREEN')[0]" :articleId="id" />
        </template>
        <el-button
          v-else
          size="mini"
          class="btn-outline"
          @click="buyAds('RIGHT_SIDE_OF_SCREEN')"
          :disabled="isPreview || isPreviewAdv"
          >{{ $t('BUY_ADVERTISEMENT' )}}</el-button
        >
      </el-col>
      <!-- RIGHT ADVERTISEMENT -->
    </el-row>

    <comment-section :articleId="Number(id)" :disabled="isPreview || isPreviewAdv" />

    <!-- Related Articles -->
    <template v-if="!isPreview && !isPreviewAdv">
      <el-divider content-position="left"
        ><span class="text-primary">{{
          $t("RELATED_ARTICLES")
        }}</span></el-divider
      >
      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" :gutter="40">
        <el-col v-for="el in relatedArticles" :key="el.id">
          <article-summary
            :orientation="isMobile ? 'portrait' : 'landscape'"
            size="small"
            clickable
            hideName
            :withWhiteBg="isMobile"
            :withShadow="isMobile"
            withImage
            :articleObj="el"
          />
        </el-col>
      </el-row>
    </template>

    <ads-dialog
      v-if="isAuthenticated"
      v-model="dialogVisible"
      :articleId="Number(id)"
      :preselectPosition="selectedPosition"
    />
    <ads-popup v-model="adsVisible" :adsObj="popupAdsObj" :nextFn="nextFn" :articleId="id" :isPreview="isPreview" />

    <!-- MIDDLE ADVERTISEMENT -->
    <div id="middle-ads">
      <template v-if="getAdvertisement('MIDDLE_OF_SCREEN')">
        <el-image
          class="pointer"
          :class="{'ads': getAdsImgUrl(getAdvertisement('MIDDLE_OF_SCREEN')[0].fileUploadList)}"
          lazy
          :src="getAdsImgUrl(getAdvertisement('MIDDLE_OF_SCREEN')[0].fileUploadList)"
          fit="cover"
          alt=""
          @click.native="
            goToAdv(getAdvertisement('MIDDLE_OF_SCREEN')[0].advertisementLink)
          "
        >
          <image-failed slot="error" />
        </el-image>
        <ads-complain v-if="!isPreview && !isPreviewAdv" :adsObj="getAdvertisement('MIDDLE_OF_SCREEN')[0]" :articleId="id" />
      </template>
      <div v-else class="text-center">
        <el-button
          size="small"
          class="btn-outline"
          @click="buyAds('MIDDLE_OF_SCREEN')"
          :disabled="isPreview || isPreviewAdv"
          >{{ $t('BUY_ADVERTISEMENT' )}}</el-button
        >
      </div>
    </div>
    <!-- MIDDLE ADVERTISEMENT -->

  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js";
import AdsComplain from "./components/ads-complain.vue";
import AdsDialog from "./components/ads-position-dialog.vue";
import AdsPopup from "./components/ads-popup.vue";
import ArticleSummary from "./components/article-summary.vue";
import CommentSection from "./components/comment-section.vue";
import SocialNetwork from "@/components/social-sharing/index.vue"
import ImageFailed from "@/components/image-failed.vue"
import { getArticleList, readArticle, getArticleByMetaTitle, getArticleById } from "@/api/article";
import { getAdsList } from "@/api/advertisement";
import { mapGetters, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import cssVariables from "@/assets/style/_variables.scss"
import { toUndashAndEncode } from "@/utils/helpers.js";
import ArticleLikeDislikeSection from './components/article-like-dislike-section.vue';

export default {
  name: "DetailPage",
  mixins: [generalMixin],
  props: ['aId'],
  components: {
    ArticleSummary,
    CommentSection,
    AdsPopup,
    AdsComplain,
    AdsDialog,
    SocialNetwork,
    ImageFailed,
    ArticleLikeDislikeSection
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState('article', ['draftArticle']),
    ...mapState('advertisement', ['draftAds']),
    getArticleColumn() {
      return this.isMobile ? 24 : 19;
    },
    getBody() {
      if (this.articleObj) {
        return this.$options.filters.translate(this.articleObj.body,null,this.$route.query.locale);
      }
      return null;
    },
    isPreview() {
      // Reserved '0' for preview mode
      // console.log(this.$route.params.title)
      return this.$route.params.title == '0'
    },
    isPreviewAdv() {
      // Reserved '00' for preview mode
      // console.log(this.$route.params.title == "00")
      return this.$route.params.title == '00'
    }
  },
  data() {
    return {
      id: this.aId || 0,
      metaTitle: this.$route.params.title,
      isLoading: false,
      adsLoading: false,
      articleObj: {},
      relatedArticles: [],
      dialogVisible: false,
      adsVisible: false,
      adsList: [],
      selectedPosition: null,
      popupAdsObj: {},
      rightAdsTopVal: "0px",
      rightAdsBtmVal: "0px",
      nextFn: null,
    };
  }, 
  watch: {
    "$route.params.title"(val) {
      if (val) {        
        this.metaTitle = val;
        this.mountedFn();
      }
    },
  },
  methods: {
    previewLanguage() {
      let lang = this.$route.query.locale || null 
      if(lang){ 
        this.$store.dispatch('app/setPreviewLocale', lang)
      }
    },
    getAdsImgUrl(fileList) {
      if(fileList.length > 0) {
        let lang = this.$route.query.locale || this.locale 
        let ads = fileList.find((el) => el.language.code == lang)
        // random put an ads, if that ads not available on current lang
        return ads ? ads.upload?.url : fileList[0].upload?.url
      } else { return null }
    },
    buyAds(position) {

      if(position) this.selectedPosition = position;
      
      if (this.isAuthenticated) this.dialogVisible = true;
      else this.goTo("/auth/sign-in");
    },
    goToAdv(path) {
      if(!path) return
      window.open(path);
    },
    async getArticle() {
      this.isLoading = true;
      const title = toUndashAndEncode(this.metaTitle)
      await getArticleByMetaTitle(title)
        .then((res) => {
          if (res.status == "ok") {
            this.articleObj = res.result;
            this.id = res.result.id
          } else {
            this.$router.push('/')
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getArticleById(id) {
      this.isLoading = true;
      await getArticleById(id)
        .then((res) => {
          if (res.status == "ok") {
            this.articleObj = res.result;
            this.id = res.result.id
          } else {
            this.$router.push('/')
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getArtcleAds() {
      this.adsLoading = true;
      const adsQuery = {
        advertisement: {
          status: "ACTIVE",
          article: {
            id: parseInt(this.id),
          },
        },
      };
      await getAdsList(adsQuery)
        .then((res) => {
          if (res.status != "ok") return;
          this.adsList = cloneDeep(res.result.list);
          this.handleMiddleAds();

          if (this.getAdvertisement("POPUP_DURING_ARTICLE_ENTRY")) {
            this.popupAdsObj = cloneDeep(
              this.getAdvertisement("POPUP_DURING_ARTICLE_ENTRY")[0]
            );
            this.adsVisible = true;
          }
        })
        .catch(this.handleError)
        .finally(() => (this.adsLoading = false));
    },
    handleMiddleAds() {
      var advBlock = this.$el.querySelector(".adv-block");
      var adv = document.getElementById('middle-ads')
      // console.log(advBlock,adv)
      if(advBlock && adv) this.$nextTick(() => advBlock.appendChild(adv))
    },
    getAdvertisement(position) {
      if(!this.isPreviewAdv) {
        const ads = this.adsList.filter(
          (ads) => ads.position === position && ads.status === "ACTIVE"
        );
        return ads.length > 0 ? ads : null;
      } else {
        // Preview mode
        const ads = this.adsList.filter(
          (ads) => ads.position === position
        );
        return ads.length > 0 ? ads : null;
      }
    },
    async getRelatedArticles(byCategory = true) {
      this.relatedArticles = [];
      const postData = {
        offset: 0,
        limit: 10,
        desc: true,
        published: true,
        orderCol: "publish_date",
        categoryActiveOnly: true,
      };
      if (byCategory) {
        // postData.genericRecord = { id: this.articleObj.category.id };
      }
      await getArticleList(postData).then((res) => {
        if (res.result?.list != null) {
          res.result.list.forEach((el) => {
            if (
              // prevent duplication and this article
              this.relatedArticles.find((el2) => el2.id == el.id) == null &&
              el.id != this.id &&
              this.relatedArticles.length < 3
            ) {
              this.relatedArticles.push(el);
            }
          });
        }
      });
    },
    async getAttendance() {
      if (!this.isAuthenticated) return
      await readArticle().catch(this.handleError);
    },
    async mountedFn() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.relatedArticles = [];

      if (this.metaTitle && this.metaTitle != '' && !this.isPreview && !this.isPreviewAdv) {

        await this.getArticle();

        await this.getArtcleAds();
        await this.getAttendance();
        await this.getRelatedArticles(true);
        if (this.relatedArticles.length < 3) {
          await this.getRelatedArticles(false); // not enough from the category, get from others
        }
      } else if(this.isPreview && !this.isPreviewAdv) {
        console.log('isPreview')
        this.previewLanguage()
        let previewData = JSON.parse(this.draftArticle)
        this.articleObj = previewData
        this.articleObj.coverPhoto = previewData.fileUpload
      } else if (this.isPreviewAdv) {
        console.log('isPreviewAdv')
        this.previewLanguage()
        let previewAdv = JSON.parse(this.draftAds)
        this.adsList.push(previewAdv)        
        await this.getArticleById(previewAdv.article.id);
        if(previewAdv.position == 'MIDDLE_OF_SCREEN'){
          this.$nextTick(() => this.handleMiddleAds())
        } else if (previewAdv.position == "POPUP_DURING_ARTICLE_ENTRY"|| previewAdv.position == "POPUP_DURING_ARTICLE_EXIT") {
            this.popupAdsObj = cloneDeep(previewAdv);
            this.adsVisible = true;
        }
      }
    },
    onScroll() {
      const viewportHeight = window.innerHeight;
      const mainTop = Number(document.getElementById("article-main-content").getBoundingClientRect().top);
      const mainBtm = Number(document.getElementById("article-main-content").getBoundingClientRect().bottom);
      const adsHeight = Number(document.getElementsByClassName("adv-right")[0]?.offsetHeight);
      const headerHeight = Number(cssVariables.header2Height.replaceAll("px", "")) + Number(cssVariables.header3Height.replaceAll("px", ""))

      const isMainOverHeader = mainTop <= headerHeight
      const isAdsOverMain = (adsHeight + headerHeight) >= mainBtm

      if (isMainOverHeader && !isAdsOverMain) {
        // scroll in between
        this.rightAdsTopVal = (headerHeight+ 10)+"px"
        this.rightAdsBtmVal = "auto"
        
      } else {
        if (isAdsOverMain) {
          // scroll bottom part
          this.rightAdsTopVal = "auto"
          this.rightAdsBtmVal = (viewportHeight - mainBtm)+"px"
        } else {
          // scroll top part 
          this.rightAdsTopVal = mainTop+"px"
          this.rightAdsBtmVal = "auto"
        }
      }
    },
  },
  mounted() {
    // console.log(this.isPreviewAdv)
    this.mountedFn();

    this.onScroll() // initial call
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  async beforeRouteLeave(_, __, next) {
    if (this.getAdvertisement("POPUP_DURING_ARTICLE_EXIT")) {
      this.popupAdsObj = cloneDeep(
        this.getAdvertisement("POPUP_DURING_ARTICLE_EXIT")[0]
        );
        this.adsVisible = true;
        this.nextFn = next;
      } else {
        next();
      }
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#public-detail {
  .btn-outline {
    border: 1px solid $primary;
    border-radius: 5px;
    text-align: left;
    font: normal normal 600 12px/32px $fontBase;
    letter-spacing: 0px;
    color: $primary;
    background: transparent;
    white-space: break-spaces;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    &:hover {
      box-shadow: 0px 8px 39px -11px rgb(0 0 0 / 50%);
      border-color: #fa5b1d5c;
    }
  }

  .adv-grey-bg {
    background: #ebebeb 0% 0% no-repeat padding-box;
  }

  .adv-top {
    height: 196px;
    margin-bottom: 1rem;
  }

  .adv-right {
    position: fixed;
    height: calc(262px * 3);
    width: 262px;
    text-align: center;
    border-radius: 5px;
    z-index: 1;

    right: 15px;
    @media (min-width: $viewMaxWidth) {
      right: calc((100vw - $viewMaxWidth + 30px) / 2);
    }

    button {
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      padding: 0;
      width: 80%;
    }
    .adv-right-img {
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-height: 100%;
      height: 100%;
      &.ads {
        box-shadow: 0px 8px 36px -14px rgb(0 0 0 / 47%);
        border-radius: 10px;
      }
    }
  }

  #middle-ads {
    position: absolute;
    left: 0;
    width: 100%;
    height: 11rem;
    background: #ebebeb 0% 0% no-repeat padding-box;
    align-items: center;
    justify-content: center;
    display: inline-flex;

    .ads{
      height: 100%;
      box-shadow: 0px 8px 36px -14px rgb(0 0 0 / 47%);
      border-radius: 10px;
      max-width: 100%;
      width: 100%;
      img.el-image__inner {
          max-width: 100%!important;
          height: 100%!important;
      }
    }
    .complain-effect .text {
      font-family: $fontBase;
    }
  }

  // .adv-block === #adv-middle
  #adv-middle,
  .adv-block {
    width: 100%;
    height: 10.75rem;
    background: #ebebeb 0% 0% no-repeat padding-box;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin: 5px auto;
    color: #8a8a8a;
    letter-spacing: .2rem;
  }

  // landscape ads
  .adv-top .ads, .adv-block img {
      height: 100%;
      box-shadow: 0px 8px 36px -14px rgb(0 0 0 / 47%);
      border-radius: 10px;
      width: 100%;
    }
  

  .article {
    height: 100%;
    min-height: 85vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;

    .article-content {
      padding: 1rem;
      > p > p {
        min-height: 1rem;
      }
      ul {
        margin-left: 1rem;
      }
      .ql-video {
        display: block;
        max-width: 100%;
        width: 55rem;
        height: 31rem;
        margin: 0 auto;
      }
      .ql-align-center , p > {
        img {
          max-height: 50rem;
          max-width: 100%;
          height: 100%;
          width: auto;
          margin: 0 auto;
          display: block;
        }
      }
      .ql-align-center {
        text-align: center;
      }
    }

    .article-header {
      .upper-part > h2 {
        font-size: 2rem;
        line-height: 3rem;
        -webkit-box-orient: unset;
      }
      .upper-part > p {
        -webkit-box-orient: unset;
      }
      // .summary-detail .upper-part {
      //   padding-bottom: 0rem;
      // }
    }
    .article-summary {
      .el-image {
        height: 325px;
        max-height: unset;
        width: 100%;
      }
      .summary-detail .upper-part .article-title {
        margin-bottom: 1rem;
      }
    }
  }

  .article-bottom {
    margin: auto 1rem;
    > .el-row {
      margin-bottom: 1.75rem;
      font-size: 14px;
      color: #878787;
      .custom-icon {
        margin-right: 5px;
      }
    }
    .complain, .complain > span{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 0.7rem;
    }
    a {
      display: flex;
      align-items: center;
    }
    .buttons-col {
      text-align: right;
      .btn {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100px;
      }
    }
    .icon-popup-ad {
      margin-left: 2rem;
    }
  }

  .el-divider__text.is-left {
    left: 0px;
    padding-left: 0;
    background-color: $bgColor;
    font: normal normal bold 18px/42px $fontBase;
    letter-spacing: 0px;
    color: #1c1d21;
  }
}

.popup-share-article {
  border-radius: 10px;
    .el-divider {
      background-color: #ededed;
      margin: 0.3rem;
      width: 95%;
    }
    .copy {
      cursor: pointer;
      font-size: 12px;
      line-height: 20px;
      display: flex;
    }
  }

.mobile {
  #public-detail {
    .article {    
      min-height: unset;
      .article-header {
        .upper-part > h2 {
          font-size: 1.5rem;
        }
      } 
      .article-content {
        img {
          max-height: unset;
          width: 100%;
          height: 100%;
        }
      }
    }
    .adv-top .ads, .adv-block img {
      height: 100%;
      max-width: 100%;
    }
    .article .el-image {
      height: 8rem;
    }
    .article-summary {
      margin-bottom: 10px;
    }
    .adv-top {
      height: 7rem;
    }
    #adv-middle, .adv-block {
      letter-spacing: 0;
      height: 7rem;
      font-size: 0.75rem;
      font-family: $fontBase;
    }
    .article-bottom {
      > .el-row {
        margin-bottom: 0;
      }
      .icon-complain-article {
        margin-right: 5px;
      }
      .complain {
        display: inline-block;
        a {
          float: left;
        }
      } 
      .buttons-col {
        text-align: left;
        margin-top: 1rem;
        display: grid;
        .btn {
          justify-content: start;
          margin-bottom: 1rem;
        }
      }
      .el-divider--horizontal {
        margin: 10px 0;
      }
    }

  }
}
</style>
