<template>
  <span
    class="complain-effect"
    @click="redirect()"
  >
    <i class="custom-icon icon-complain-adv mini" />
    <span class="text slide-left">{{ $t("CLICK_TO_COMPLAIN") }}</span>
  </span>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  mixins: [],
  props: {
    adsObj: {
      type: Object,
      required: true,
    },
    articleId: { type: [ String, Number ], required: true },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    redirect() {
      console.log('herhehrerh');
      let url = ''
      if(!this.isAuthenticated) url = '/auth/sign-in'
      else url = `/complain/advertisement/${this.adsObj.id}`

      window.open(url)
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/style/mixin/animation.scss";

.complain-effect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translateY(0%);
  right: 0;
  bottom: 0px;
  background: $white;
  cursor: pointer;

  .custom-icon.mini {
    z-index: 1;
  }

  .text {
    display: none;
    color: #00a4c4;
    background: #ffffff;
    font-size: 12px;
    line-height: 22px;
  }
  &:hover {
    .text {
      display: inline-block;
      width: 120px;
      position: absolute;
      height: 100%;
      left: -120px;
      text-align: center;
    }
  }
}
</style>