<template>
  <el-row v-loading="isLoading" class="comment-box shadowed">
    <!-- Parent Comment Part -->
    <el-row class="section">
      <el-col :span="24">
        <el-row type="flex" justify="space-between" align="middle" class="top">
          <el-col :span="isMobile ? 3 : 1" class="text-center">
            <el-avatar
              :size="isMobile ? 30 : 38"
              :src="getPhotoUrl(commentObj.user)"
            />
          </el-col>
          <el-col>
            <p class="person-detail">
              {{ getFullName(commentObj.user) }} <i class="el-icon-time"></i>
              <small>{{ timeElapsed(commentObj.createdDate) }}</small>
            </p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="isMobile ? 24 : 23" :offset="isMobile ? 0 : 1">
        <p>{{ commentObj.text }}</p>
      </el-col>
    </el-row>

    <!-- Reply comment textbox  -->
    <el-row v-if="showInputField">
      <el-col :span="24">
        <el-row class="comment-textbox">
          <el-col :span="isMobile ? 3 : 1" class="text-center">
            <el-avatar :size="isMobile ? 30 : 38" :src="getPhotoUrl(user)" />
          </el-col>
          <el-col :span="22" :xs="24">
            <el-input
              v-model="commentText"
              type="textarea"
              resize="none"
              :rows="4"
              :placeholder="$t('COMMENT_PLACEHOLDER')"
            />
            <el-row
              class="post-button-row"
              type="flex"
              justify="end"
              align="middle"
            >
              <el-col :span="isMobile ? 12 : 4">
                <el-button
                  class="button reversed-color black w-100"
                  :disabled="disabled"
                  @click="showInputField = !showInputField"
                >
                  {{ $t("CANCEL") }}
                </el-button>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="isMobile ? 12 : 4">
                <el-button
                  class="button primary w-100"
                  :disabled="disabled"
                  @click="postComment()"
                >
                  {{ $t("REPLY") }}
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row>
      <el-col :offset="isMobile ? 3 : 1" :span="isMobile ? 20 : 22">
        <!-- Reply Comment Part -->
        <template v-if="isAuthenticated && !disabled">
          <!-- reply button  -->
          <el-button
            v-if="!showInputField"
            size="small"
            class="button primary link-style"
            @click="showInputField = !showInputField"
          >
            {{ $t("REPLY") }}
          </el-button>
        </template>

        <!-- View Replies  -->
        <template v-if="commentObj.hasChild">
          <el-button
            v-if="totalAvailableReplied > totalLoadedReplied && totalLoadedReplied === 0"
            size="small"
            class="button primary link-style"
            @click="loadReplies()"
          >
            {{ $t("VIEW_REPLIES") }}
          </el-button>
        </template>

        <!-- Children Comment Part -->
        <template v-if="repliedList.length > 0">
          <el-row
            v-for="el in repliedList"
            :key="'comment_' + el.id"
            class="comment-box shadowed replies"
          >
            <el-col :span="24">
              <el-row
                type="flex"
                justify="space-between"
                align="middle"
                class="top"
              >
                <el-col :span="isMobile ? 3 : 1" class="text-center">
                  <el-avatar
                    :size="isMobile ? 30 : 38"
                    :src="getPhotoUrl(el.user)"
                  />
                </el-col>
                <el-col>
                  <p class="person-detail">
                    {{ getFullName(el.user) }} <i class="el-icon-time"></i>
                    <small>{{ timeElapsed(el.createdDate) }}</small>
                  </p>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="isMobile ? 24 : 23" :offset="isMobile ? 0 : 1">
              <p>{{ el.text }}</p>
            </el-col>
          </el-row>
        </template>

        <!-- View Replies  -->
        <template v-if="commentObj.hasChild">
          <el-button
            v-if="totalAvailableReplied > totalLoadedReplied && totalLoadedReplied > 0"
            size="small"
            class="button primary link-style"
            @click="loadReplies()"
          >
            {{ $t("VIEW_MORE_REPLIES") }}
          </el-button>
        </template>

      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { commentMixin } from "@/utils/_mixins/comment.js";
import { timeElapsed } from "@/utils/helpers.js";

const fetchCommentCount = 3

export default {
  name: "CommentBox",
  mixins: [commentMixin],
  props: {
    commentObj: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    articleId: { type: Number, required: true },
  },
  data() {
    return {
      showInputField: false,
      repliedList: [],
      totalLoadedReplied: 0,
      totalAvailableReplied: 1,
      timeElapsed
    };
  },
  computed: {
    parentCommentId() {
      return this.commentObj?.id ?? null;
    },
  },
  methods: {
    async loadReplies() {
      if (this.isLoading) return;

      this.isLoading = true;
      let result = await this.getList(fetchCommentCount, false);
      this.isLoading = false;

      this.repliedList.push(...result);
    },
    reset() {
      this.repliedList = []
      this.totalAvailableReplied = 1
      this.totalLoadedReplied = 0
      this.commentText = ""
    },
    async mountedFn() {
      this.reset()
      this.isLoading = true
      this.repliedList = await this.getList(fetchCommentCount, false)
      this.isLoading = false
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
.comment-box {
  margin-bottom: 1.438rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 1rem;
  p {
    font: normal normal normal 16px/22px $fontBase;
    letter-spacing: 0px;
    color: #767396;
    padding-left: 0.5rem;

    &.person-detail {
      font: normal normal 600 16px/25px $fontBase;
      letter-spacing: 0px;
      color: #666c79;
      display: inline-block;
      small {
        font: normal normal normal 12px/12px $fontBase;
        letter-spacing: 0px;
        color: #868588;
      }
    }
  }
  .el-icon-time {
    margin: 0 2px;
    font-size: 12px;
  }
  .el-avatar {
    margin-right: 10px;
  }
  .comment-textbox {
    margin-bottom: 0;
  }
  .replies {
    margin-bottom: 1rem;
  }
  .section {
    margin-bottom: 1rem;
  }
  .link-style {
    padding: 0;
  }
  .post-button-row {
    padding-inline: 0!important;
  }
}

.mobile {
  .top {
    margin-bottom: 1rem;
  }
  .comment-box .p {
    padding-left: 0;
  }
}
</style>