<template>
  <el-dialog
    :visible.sync="isVisible"
    custom-class="ads-entry-popup"
    :width=" isMobile ? '90%':'500px'"
    center
    destroy-on-close
    :close-on-click-modal="false"
  >
    <el-image
      class="w-100 adv-right-img pointer h-100"
      lazy
      :src="getAdsImgUrl(adsObj.fileUploadList)"
      fit="cover"
      alt=""
      @click.native="goToAdv(adsObj.advertisementLink)"
    >
      <image-failed slot="error" />
    </el-image>
    <ads-complain v-if="!isPreview" :adsObj="adsObj" :articleId="articleId" />
    <i @click="exit()" class="el-dialog__close el-icon el-icon-close"></i>
  </el-dialog>
</template>

<script>
// import cloneDeep from "lodash/cloneDeep";
import { generalMixin } from "@/utils/general-mixin.js";
import adsComplain from "./ads-complain.vue";
import ImageFailed from "@/components/image-failed.vue"

export default {
  mixins: [generalMixin],
  components: {
    adsComplain,
    ImageFailed
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    adsObj: { type: Object },
    nextFn: { type: Function },
    articleId: { type: [ String, Number ], required: true },
    isPreview: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getAdsImgUrl(fileList) {
      if(fileList && fileList.length > 0) {
        let ads = fileList.find((el) => el.language.code == this.locale)
        // random put an ads, if that ads not available on current lang
        return ads ? ads.upload?.url : fileList[0].upload?.url
      } else { return null }
    },
    goToAdv(path) {
      if(!path) return
      window.open(path);
    },
    exit() {
        if(this.nextFn) this.nextFn()
        else this.isVisible = false
    }
  },
};
</script>
<style lang="scss">
.ads-entry-popup {
  box-shadow: 0 1px 20px 0px rgb(0 0 0 / 13%);
  height: 500px;

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    display: flex;
    height: 100%;
    // .complain-effect {
    //   bottom: 6px!important;
    // }
  }
  .el-dialog__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 96%;
    height: 3rem;
    width: 3rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-1rem);
    cursor: pointer;
    font-size: 1.75rem;
  }
}
.mobile {
  .ads-entry-popup {
    .el-dialog__close {
      left: 94%;
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
</style>